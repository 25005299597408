/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import ContentfulImage from 'components/image'
import { IPageSectionHero } from 'interfaces/page-section-hero'
import { BLOCKS } from '@contentful/rich-text-types'

interface IProps {
    pageSectionHero: IPageSectionHero
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <div className="text-4xl xl:text-7xl lg:text-6xl text-black text-center">{children}</div>
    ),
}

export const HeroSectionImageBelow = ({ pageSectionHero }: IProps): React.ReactElement => {
    const { richHeader, mobileBackgroundImagesCollection, backgroundImagesCollection } = pageSectionHero

    return (
        <>
            <section className="h-40-vh md:h-50-vh lg:h-60-vh my-4 overflow-hidden">
                {/* content */}
                <div className="h-1/2 md:h-1/3 px-5 xl:my-12 flex items-center justify-center">
                    <div className="flex flex-col items-center w-full">
                        <div className="md:w-[75%]">
                            <ContentfulRichText content={richHeader.json} renderNode={headerRenderNode} />
                        </div>
                    </div>
                </div>
                <div className="h-1/2 md:h-2/3 px-3 lg:px-15 md:px-6 overflow-hidden flex flex-col justify-center">
                    <div className="max-h-full">
                        <ContentfulImage
                            className="object-cover object-top rounded-md md:rounded-lg"
                            image={backgroundImagesCollection?.items?.[0]}
                            mobileImage={mobileBackgroundImagesCollection?.items?.[0]}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}
