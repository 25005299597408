import * as React from 'react'
import { DIRECTION_VERTICAL } from 'enum/direction'
import useIntersectionObserver from './useIntersectionObserver'

interface Args {
    element: HTMLElement
    direction?: DIRECTION_VERTICAL // direction the sticky element has moved out of viewport
}

/**
 * This hook observes if an element has exited viewport or not
 * @param Arguments Args
 * @returns boolean
 */
const useScreenExitDetection = ({ element, direction = DIRECTION_VERTICAL.BOTH }: Args): boolean => {
    const { entry } = useIntersectionObserver({ element })

    if (!entry) {
        return
    }

    // has not exited screen
    if (entry.isIntersecting) {
        return false
    }

    switch (direction) {
        case DIRECTION_VERTICAL.TOP:
            if (entry.boundingClientRect.top < 0) {
                return true
            }
            break
        case DIRECTION_VERTICAL.BOTTOM:
            if (entry.boundingClientRect.top > 0) {
                return true
            }
            break
        case DIRECTION_VERTICAL.BOTH:
        default:
            return true
    }
}

export default useScreenExitDetection
