/* eslint-disable react/display-name */
import * as React from 'react'
import HeroImage from 'components/hero-image'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionHero } from 'interfaces/page-section-hero'
import { BLOCKS } from '@contentful/rich-text-types'
import Link from 'next/link'

interface IProps {
    pageSectionHero: IPageSectionHero
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => (
        <h1 className="section--header hero--header">{children}</h1>
    ),
}

const paragraphRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => <p className="section--paragraph">{children}</p>,
}

export const HeroSectionPaddedWithContentTopRight = ({ pageSectionHero }: IProps): React.ReactElement => {
    if (!pageSectionHero) {
        return null
    }

    const { backgroundImagesCollection, mobileBackgroundImagesCollection, richHeader, paragraphCopy, primaryCtaLink } =
        pageSectionHero ?? {}

    return (
        <section className="my-0 lg:p-15 relative lg:h-[calc(100vh-var(--headerPlusBannerHeight)-var(--secondaryNavbarHeight))] lg:max-h-[var(--heroMaxHeight)] overflow-hidden -mb-24">
            <div className="relative h-full">
                {/* hero image */}
                <HeroImage
                    className="w-full h-full object-cover lg:rounded-2lg"
                    backgroundImage={backgroundImagesCollection?.items?.[0]}
                    mobileBackgroundImage={mobileBackgroundImagesCollection?.items?.[0]}
                />
            </div>

            {/* content */}
            <div className="bg-white px-5 py-8 sm:p-6 lg:w-[55ch] lg:absolute lg:top-28 lg:right-[120px] lg:rounded-2lg">
                {richHeader?.json && (
                    <ContentfulRichText
                        content={richHeader.json}
                        renderNode={headerRenderNode}
                        className="max-w-[70%] lg:max-w-none text-3xl lg:text-6xl tracking-tight leading-110% font-medium"
                        ignoreMarkdownStyles
                    />
                )}

                {paragraphCopy?.json && (
                    <ContentfulRichText
                        content={paragraphCopy.json}
                        renderNode={paragraphRenderNode}
                        className="max-w-[35ch] lg:max-w-none font-medium text-xbase leading-140% mt-1.5 lg:mt-0.5"
                        ignoreMarkdownStyles
                    />
                )}

                {/* cta on desktop */}
                {primaryCtaLink?.href && (
                    <Link
                        href={primaryCtaLink.href}
                        className="button button-primary can-hover:hover:bg-white can-hover:hover:text-black transition-colors duration-200 mt-8 lg:mt-6 block lg:inline-block min-w-[165px]"
                    >
                        {primaryCtaLink.name}
                    </Link>
                )}
            </div>
        </section>
    )
}
