import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { IPageSectionHero } from 'interfaces/page-section-hero'
import HeroImage from 'components/hero-image'
import { getHTMLClassTypeNameFromSection } from '@/lib/util/css'

interface IProps {
    pageSectionHero: IPageSectionHero
}

export const HeroSectionWithoutCTA = ({ pageSectionHero }: IProps): React.ReactElement => {
    const { backgroundImagesCollection, mobileBackgroundImagesCollection, richHeader } = pageSectionHero ?? {}

    let headerParagraphNodeCount = 0

    const headerRenderOptions = {
        renderNode: {
            // eslint-disable-next-line react/display-name
            [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => {
                headerParagraphNodeCount++

                return (
                    // set leading in every breakpoint that is used to define text-{size}
                    // this is because text-{size} defines it's onw line height
                    <div
                        className={`section--header hero--header text-5xl lg:text-7.5xl lg:whitespace-pre ${
                            headerParagraphNodeCount === 2 ? 'text-right lg:text-left mt-10 lg:mt-0' : ''
                        }`}
                    >
                        {children}
                    </div>
                )
            },
        },
    }

    return (
        <>
            <section className={`${getHTMLClassTypeNameFromSection(pageSectionHero)} bg-white`}>
                {/* hero content */}
                <div
                    className={`px-5 py-9 lg:px-16 lg:pt-17.5 lg:pb-12 flex flex-col
                     relative z-10 `}
                >
                    <h1>
                        <ContentfulRichText content={richHeader?.json} renderOptions={headerRenderOptions} />
                    </h1>
                </div>
                {/* hero image */}
                <HeroImage
                    className="h-36 md:h-auto w-full object-cover border-b lg:border-b-2"
                    backgroundImage={backgroundImagesCollection?.items?.[0]}
                    mobileBackgroundImage={mobileBackgroundImagesCollection?.items?.[0]}
                />
            </section>
        </>
    )
}
