import * as React from 'react'
import { IFormInput } from 'interfaces/form-input'

interface IProps {
    input: IFormInput
    buttonAddOn?: React.ReactElement
}

export const TextInput = ({
    input,
    buttonAddOn,
    className = '',
    ...props
}: IProps & React.ComponentProps<'input'>): React.ReactElement => {
    const [buttonWrapperEl, setButtonWrapperEl] = React.useState<HTMLDivElement>()

    const buttonWrapperRef = React.useCallback((node) => {
        if (node !== null) {
            setButtonWrapperEl(node)
        }
    }, [])

    if (!input) {
        return null
    }

    const { placeholder, label } = input

    if (!React.isValidElement(buttonAddOn)) {
        return (
            <input
                type="text"
                title={placeholder}
                placeholder={placeholder}
                className={`bg-white rounded-2lg border border-gray-medium px-4 py-2.5 text-center font-bold placeholder:text-gray w-full ${className}`}
                aria-label={label}
                {...props}
            />
        )
    }

    return (
        <div
            className="grid grid-cols-[1fr,auto] bg-white rounded-2lg border border-gray-medium relative"
            style={{
                ['--buttonWidth' as string]: buttonWrapperEl?.clientWidth + 10 + 'px',
            }}
        >
            <input
                type="text"
                title={placeholder}
                placeholder={placeholder}
                className={`text-xbase px-4 py-2.5 text-center font-bold placeholder:text-gray w-full rounded-2lg pr-[var(--buttonWidth)] ${className}`}
                aria-label={label}
                {...props}
            />

            <div ref={buttonWrapperRef} className="absolute right-0 top-0 h-full">
                {buttonAddOn}
            </div>
        </div>
    )
}
