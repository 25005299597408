/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionHero } from 'interfaces/page-section-hero'
import { BLOCKS } from '@contentful/rich-text-types'
import Link from 'next/link'
import Button from 'components/buttons/button'

interface IProps {
    pageSectionHero: IPageSectionHero
    ctaOnClick: () => void
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-3.25xl font-medium tracking-tight leading-120%">{children}</p>
    ),
}

const richSubHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="font-medium tracking-tight leading-140%">{children}</p>,
}

const HeroSectionSmall = ({ pageSectionHero, ctaOnClick }: IProps): React.ReactElement => {
    if (!pageSectionHero) {
        return null
    }

    const { richHeader, richSubHeader, header, primaryCtaLink } = pageSectionHero

    return (
        <section className="px-5 py-7.5 lg:py-12.5 bg-white">
            <div className="mx-auto max-w-lg flex flex-col justify-center items-center">
                {header && <div className="text-center text-xsm font-bold tracking-wider">{header}</div>}

                {richHeader?.json && (
                    <ContentfulRichText
                        className="text-center mt-2.5 lg:mt-5"
                        content={richHeader.json}
                        renderNode={headerRenderNode}
                    />
                )}

                {richSubHeader?.json && (
                    <ContentfulRichText
                        className="text-center mt-2.5 lg:mt-3.5"
                        content={richSubHeader.json}
                        renderNode={richSubHeaderRenderNode}
                    />
                )}

                {/* cta */}
                {typeof ctaOnClick === 'function' ? (
                    <Button onClick={ctaOnClick} className="section--primary-cta inline-block mt-2.5 lg:mt-5">
                        {primaryCtaLink?.name}
                    </Button>
                ) : (
                    primaryCtaLink?.href && (
                        <Link
                            href={primaryCtaLink.href}
                            className="button button-primary section--primary-cta inline-block mt-2.5 lg:mt-5"
                            data-cy="primary-cta"
                        >
                            {primaryCtaLink.name}
                        </Link>
                    )
                )}
            </div>
        </section>
    )
}

export default HeroSectionSmall
