import * as React from 'react'
import { IPageSectionHero } from 'interfaces/page-section-hero'
import HeroVideo from 'components/hero-video'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import { IUiResource } from 'interfaces/ui-resource'

interface IProps {
    pageSectionHero: IPageSectionHero
    className?: string
    playMediaLabel: IUiResource
}

const lgBreakPoint = screens.lg

export const HeroSectionVideo = ({ pageSectionHero, className, playMediaLabel }: IProps): React.ReactElement => {
    const isMobileScreen = useMobileScreenDetection()

    if (!pageSectionHero) {
        return null
    }

    const {
        backgroundImagesCollection,
        mobileBackgroundImagesCollection,
        videoPosterImageCollection,
        mobileVideoPosterImageCollection,
    } = pageSectionHero

    const primaryDesktopBackgroundMedia = backgroundImagesCollection?.items?.[0]
    const secondaryDesktopBackgroundMedia = backgroundImagesCollection?.items?.[1]

    const primaryMobileBackgroundMedia = mobileBackgroundImagesCollection?.items?.[0]
    const secondaryMobileBackgroundMedia = mobileBackgroundImagesCollection?.items?.[1]

    const mobileVideoPosterImage = mobileVideoPosterImageCollection?.items?.[0]
    const desktopVideoPosterImage = videoPosterImageCollection?.items?.[0]

    const mobileVideoPosterImageURL = mobileVideoPosterImage?.url + '?fm=jpg&w=640'
    const desktopVideoPosterImageURL = desktopVideoPosterImage?.url + '?fm=jpg&w=1280'

    return (
        <section className="relative">
            {/* primary media */}
            <HeroVideo
                video={isMobileScreen ? primaryMobileBackgroundMedia : primaryDesktopBackgroundMedia}
                poster={isMobileScreen ? mobileVideoPosterImageURL : desktopVideoPosterImageURL}
                className={className}
                playMediaLabel={playMediaLabel}
            />

            {/* secondary media - placed on top of primary media */}
            {(secondaryDesktopBackgroundMedia?.url || secondaryMobileBackgroundMedia?.url) && (
                <picture className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-full flex items-center">
                    {/* desktop image */}
                    {secondaryDesktopBackgroundMedia?.url && (
                        <>
                            {/* svg */}
                            {secondaryDesktopBackgroundMedia.url.endsWith('.svg') && (
                                <source
                                    type="image/svg+xml"
                                    media={`(min-width: ${lgBreakPoint})`}
                                    srcSet={secondaryDesktopBackgroundMedia.url}
                                />
                            )}

                            {/* webp */}
                            <source
                                type="image/webp"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                ${secondaryDesktopBackgroundMedia.url}?fm=webp&w=1024 1024w,
                                ${secondaryDesktopBackgroundMedia.url}?fm=webp&w=1280 1280w,
                            `}
                            />

                            {/* jpg */}
                            <source
                                type="image/jpeg"
                                media={`(min-width: ${lgBreakPoint})`}
                                srcSet={`
                                ${secondaryDesktopBackgroundMedia.url}?fm=jpg&w=1024 1024w,
                                ${secondaryDesktopBackgroundMedia.url}?fm=jpg&w=1280 1280w,
                            `}
                            />
                        </>
                    )}

                    {/* mobile image */}
                    {secondaryDesktopBackgroundMedia?.url && (
                        <>
                            {/* svg */}
                            {secondaryMobileBackgroundMedia.url.endsWith('.svg') && (
                                <source type="image/svg+xml" srcSet={secondaryMobileBackgroundMedia.url} />
                            )}

                            {/* webp */}
                            <source
                                type="image/webp"
                                srcSet={`
                                ${secondaryMobileBackgroundMedia.url}?fm=webp&w=1024 1024w,
                                ${secondaryMobileBackgroundMedia.url}?fm=webp&w=1280 1280w,
                            `}
                            />

                            {/* jpg */}
                            <source
                                type="image/jpeg"
                                srcSet={`
                                ${secondaryMobileBackgroundMedia.url}?fm=jpg&w=1024 1024w,
                                ${secondaryMobileBackgroundMedia.url}?fm=jpg&w=1280 1280w,
                            `}
                            />
                        </>
                    )}

                    <img
                        src={`${
                            secondaryMobileBackgroundMedia.url || secondaryDesktopBackgroundMedia.url
                        }?fm=jpg&w=1024`}
                        alt={
                            secondaryMobileBackgroundMedia.description ||
                            secondaryDesktopBackgroundMedia.description ||
                            secondaryDesktopBackgroundMedia.title ||
                            secondaryMobileBackgroundMedia.title
                        }
                        height={secondaryMobileBackgroundMedia.height || secondaryDesktopBackgroundMedia.height}
                        width={secondaryMobileBackgroundMedia.width || secondaryDesktopBackgroundMedia.width}
                        className="max-h-[50%] mt-10 lg:mt-0 relative lg:top-[20px]"
                    />
                </picture>
            )}
        </section>
    )
}
