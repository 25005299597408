import ContentfulImage from 'components/image'
import * as React from 'react'
import { IImage } from 'interfaces/media'

interface IProps {
    backgroundImage: IImage
    mobileBackgroundImage: IImage
    className?: string
}

export const HeroImage = ({ backgroundImage, mobileBackgroundImage, className = '' }: IProps): React.ReactElement => {
    return (
        <ContentfulImage
            image={backgroundImage}
            mobileImage={mobileBackgroundImage}
            className={className}
            isPreload={true}
        />
    )
}
