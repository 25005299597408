import { HeroSectionImageBelow } from 'components/page-sections/hero-section/hero-section-image-below'
import * as React from 'react'
import { HeroSectionWithoutCTA } from './hero-section-without-cta'
import { HeroSectionWithCTA } from './hero-section-with-cta'
import { IPageSectionHero } from 'interfaces/page-section-hero'
import { HeroSectionSplitScreen } from './hero-section-split-screen'
import { IUiResources } from 'interfaces/ui-resource'
import { HeroSectionVideo } from './hero-section-video'
import HeroSectionSmall from './hero-section-small'
import { HeroSectionSplitScreenLargeImage } from './hero-section-split-screen-large-image'
import { HeroSectionPaddedWithContentTopRight } from './hero-section-padded-with-content-top-right'
import { HeroSectionSplitScreenWithVideo } from './hero-section-split-screen-video'
import { ICommonCopyDict } from 'interfaces/common-copy'
import { heroSectionRenderTypeWhichRendersRxSearchForm } from 'misc-variables'
import { HeroSectionTokenVisualizer } from './hero-section-token-visualizer'

interface IProps {
    pageSectionHero: IPageSectionHero
    uiResources?: IUiResources
    className?: string
    ctaOnClick?: () => void
    commonCopy?: ICommonCopyDict
    internalName?: string
}

const HeroSection = ({
    pageSectionHero,
    uiResources,
    className,
    ctaOnClick,
    internalName,
    commonCopy,
}: IProps): React.ReactElement => {
    switch (pageSectionHero?.renderType) {
        case 'content center aligned':
        case 'content left aligned':
            return (
                <HeroSectionWithCTA
                    pageSectionHero={pageSectionHero}
                    internalName={internalName}
                    uiResources={uiResources}
                />
            )
        case 'just text and image':
            return <HeroSectionWithoutCTA pageSectionHero={pageSectionHero} />

        case 'content left, image right':
            return <HeroSectionSplitScreen pageSectionHero={pageSectionHero} />

        case 'content left, video right':
        case 'content left, video right, social':
        case 'content left, video right, social - alt':
            return (
                <HeroSectionSplitScreenWithVideo
                    pageSectionHero={pageSectionHero}
                    playMediaLabel={uiResources['playMediaLabel']}
                    uiResources={uiResources}
                />
            )

        case 'desktop video, static mobile':
            return (
                <HeroSectionVideo
                    className={className}
                    pageSectionHero={pageSectionHero}
                    playMediaLabel={uiResources['playMediaLabel']}
                />
            )
        case 'content flow top down':
            return <HeroSectionSmall ctaOnClick={ctaOnClick} pageSectionHero={pageSectionHero} />

        case 'content left, large image right':
        case heroSectionRenderTypeWhichRendersRxSearchForm: // content left, search center, large image right
            return (
                <HeroSectionSplitScreenLargeImage
                    pageSectionHero={pageSectionHero}
                    playMediaLabel={uiResources['playMediaLabel']}
                    uiResources={uiResources}
                    commonCopy={commonCopy}
                />
            )
        case 'content top right in a box, padded':
            return <HeroSectionPaddedWithContentTopRight pageSectionHero={pageSectionHero} />
        case 'token visualizer':
            return <HeroSectionTokenVisualizer pageSectionHero={pageSectionHero} />
        case 'header above image':
            return <HeroSectionImageBelow pageSectionHero={pageSectionHero} />
        default:
            return null
    }
}

export default HeroSection
