/* eslint-disable react/display-name */
import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionHero } from 'interfaces/page-section-hero'
import Link from 'next/link'
import { BLOCKS } from '@contentful/rich-text-types'
import { PersonalizedBottleCap } from 'svgs/personalized-bottle-cap'
import TextInput from 'components/form-inputs/text-input'
import { getPersonalizedTokenName } from 'lib/util/misc'
import { tokenSectionBackgroundImage } from 'misc-variables'

interface IProps {
    pageSectionHero: IPageSectionHero
}

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <div className="text-5xl lg:text-6.5xl xl:text-7xl font-medium leading-110% tracking-tight">{children}</div>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-xbase lg:text-base xl:text-xl font-medium leading-140%">{children}</p>
    ),
}

export const HeroSectionTokenVisualizer = ({ pageSectionHero }: IProps): React.ReactElement => {
    const [tokenName, setTokenName] = React.useState('')

    if (!pageSectionHero) {
        return null
    }

    const { richHeader, richSubHeader, primaryCtaLink, inputsCollection } = pageSectionHero

    const tokenNameInput = inputsCollection.items[0]

    const [firstName, restOfTheName] = getPersonalizedTokenName(tokenName)

    return (
        /* deducting 160px from hero height to give some breathing space + move the next
           icons section above the fold */
        <section className="section--v2 mt-0 p-0 grid grid-cols-1 lg:grid-cols-2 relative lg:min-h-[500px] lg:h-[calc(var(--screenHeightDeductingHeader)-160px)] max-h-[700px] z-30">
            <div className="overflow-hidden h-72 lg:h-auto">
                <PersonalizedBottleCap
                    className="w-full h-full scale-[2] lg:scale-[1.75] lg:-mt-8 xl:-mt-10 2xl:-mt-15"
                    firstName={firstName || undefined}
                    lastName={firstName ? restOfTheName ?? '' : undefined}
                    tokenImage={tokenSectionBackgroundImage}
                />
            </div>

            <div className="px-5 lg:pl-15 lg:pr-5 lg:pt-15 mt-5 lg:mt-0 flex flex-col items-start sm:items-center lg:items-start relative z-10 lg:col-start-1 lg:col-end-2 lg:row-start-1 lg:row-end-2 lg:mr-20 space-y-5">
                {richHeader?.json && (
                    <h2>
                        <ContentfulRichText
                            className="sm:text-center lg:text-left"
                            content={richHeader.json}
                            renderNode={headerRenderNode}
                        />
                    </h2>
                )}

                <TextInput
                    input={tokenNameInput}
                    className="!text-left sm:!text-center lg:!text-left w-full !rounded-full"
                    value={tokenName}
                    onChange={(e) => setTokenName(e.target.value)}
                />

                {richSubHeader?.json && (
                    <ContentfulRichText
                        className="mt-5 sm:text-center lg:text-left"
                        content={richSubHeader.json}
                        renderNode={subHeaderRenderNode}
                    />
                )}

                {/* cta */}
                {primaryCtaLink?.href && (
                    <Link
                        href={tokenName ? `${primaryCtaLink.href}?token_name=${tokenName}` : primaryCtaLink.href}
                        className="section--primary-cta button button-primary inline-block mt-5"
                    >
                        {primaryCtaLink.name}
                    </Link>
                )}
            </div>
        </section>
    )
}
