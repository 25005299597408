import * as React from 'react'

interface IProps {
    lines?: number
    className?: string
}

export const TextSkeleton = ({ lines = 3, className = '' }: IProps): React.ReactElement => {
    return (
        <div className={`animate-pulse gap-y-4 grid ${className}`}>
            {[...Array(lines)].map((_, i) => (
                <div
                    key={i}
                    className={`h-12 bg-gray-medium rounded-lg ${
                        i === 0 ? 'max-w-xl' : i === 1 ? 'max-w-lg' : 'max-w-sm'
                    }`}
                />
            ))}
        </div>
    )
}
